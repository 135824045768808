import { PublicClientApplication } from "@azure/msal-browser";

const clientId = process.env.VUE_APP_CLIENT_ID;
const tenantId = process.env.VUE_APP_TENANT_ID;

const redirectUri = process.env.VUE_APP_REDIRECT_URL;
const b2cauthority = `${process.env.VUE_APP_BASE_AUTHORITY}/${tenantId}/${process.env.VUE_APP_USERFLOW_POLICY_NAME}/`;

const config = {
  auth: {
    clientId,
    authority: b2cauthority,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
    knownAuthorities: [
      `${process.env.VUE_APP_BASE_AUTHORITY}/${tenantId}/${process.env.VUE_APP_USERFLOW_POLICY_NAME}/`,
      `${process.env.VUE_APP_BASE_AUTHORITY}/${tenantId}`,
      `https://login.microsoftonline.com/${tenantId}`,
    ],
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
};

const loginRequest = {
  scopes: ["openid", "profile"],
};

export const client = new PublicClientApplication(config);

export let account = null;
export let catalogUser = null;
export let catalogUserApps = null;
export let working = true;
export let authPromise = client
  .handleRedirectPromise()
  .then((resp) => {
    handleResponse(resp);
  })
  .then(() => {
    working = false;
  })
  .catch((err) => {
    console.error(err);
    working = false;
  });

export function handleResponse(resp) {
  if (resp !== null) {
    account = resp.account;
    console.log(resp.account);
  } else {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = client.getAllAccounts();
    if (currentAccounts === null) {
      return;
    } else if (currentAccounts.length > 1) {
      // Add choose account code here
      console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
      account = currentAccounts[0];
      console.log(currentAccounts[0]);
    }
  }
}

export async function setUser(user) {
  catalogUser = user;
  return catalogUser;
}

export async function setMyapps(applications) {
  catalogUserApps = applications;
  return catalogUserApps;
}

export async function signIn() {
  await client.loginRedirect(loginRequest);
}

export function signOut() {
  const logoutRequest = {
    account: account,
  };

  client.logout(logoutRequest);
}

window.signOut = signOut;
export async function getToken() {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  loginRequest.account = account;
  return await client.acquireTokenSilent(loginRequest).catch(async () => {
    console.warn(
      "silent token acquisition fails. acquiring token using redirect"
    );
    // fallback to interaction when silent call fails
    return await client.acquireTokenRedirect(loginRequest);
  });
}
