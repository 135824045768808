export function getOS() {
  let os = "other";
  if (/iPhone|iPad|iPod/i.test(navigator.platform)) {
    os = "ios";
  } else if (
    // ipad ios 13+
    navigator.platform === "MacIntel" &&
    navigator.maxTouchPoints > 1
  ) {
    os = "ios";
  } else if (/Linux/i.test(navigator.platform)) {
    os = "android";
  } else if (/Android/i.test(navigator.platform)) {
    os = "android";
  }
  return os;
}
