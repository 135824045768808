<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card color="#3a89c9" class="px-5 py-3">
          <template v-slot:heading>
            <v-snackbar
              v-model="downloadSnackbar"
              :multi-line="true"
              timeout="-1"
            >
              Your app installation has begun. Please follow the prompts, and
              then close this page to locate your app on your device.
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="downloadSnackbar = false"
                >
                  OK
                </v-btn>
              </template>
            </v-snackbar>
            <div class="appstitle" style="display: inline">My Apps</div>
            <v-dialog
              v-model="instructionsdialog"
              max-width="600px"
              scrollable
              v-if="os !== 'other'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="float: right; margin-top: 10px"
                  v-bind="attrs"
                  v-on="on"
                  color="blue-grey"
                >
                  Installation Help
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col v-if="os == 'ios'">
                        <h2>iOS Installation Steps</h2>
                        <br />
                        <v-list-item style="text-align: left">
                          <h4>
                            1) Tap the "DOWNLOAD" button for the app you want to
                            install
                          </h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <h4>
                            2) When the image below pops up, tap "Open"<br /><img
                              src="../assets/iosinstructions1.png"
                              style="max-width: 100%"
                            />
                          </h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <h4>
                            3) Tap "Install" on the next pop-up<br /><img
                              src="../assets/iosinstructions2.png"
                              style="max-width: 100%"
                            />
                          </h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <h4>
                            4) Close your browser and locate the app on your
                            home screen<br /><img
                              src="../assets/iosinstructions3.png"
                              style="max-width: 100%"
                            />
                          </h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <h4>
                            5) The first time you open your app, you may see the
                            message below. If so, tap "Cancel" and complete step
                            6.<br /><img
                              src="../assets/iosinstructions4.png"
                              style="max-width: 100%"
                            />
                          </h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <h4>6) From the Settings app, do the following:</h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Go to Settings > General > Device Management.
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Under the "Enterprise App" heading, tap "Black &
                            Veatch Corporation"
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Tap "Trust Black & Veatch" and confirm your choice.
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Tap "Verify App"
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Exit Settings and open your app
                          </li>
                        </v-list-item>
                      </v-col>
                      <v-col v-else-if="os == 'android'">
                        <h2>Android Installation Steps</h2>
                        <br />
                        <v-list-item style="text-align: left">
                          <h4>
                            1) Tap the "DOWNLOAD" button for the app you want to
                            install
                          </h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <h4>
                            2) If the image below pops up, tap "Settings"<br /><img
                              src="../assets/androidinstructions1.jpg"
                              style="max-width: 100%"
                            />
                          </h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <h4>3) From settings, do the following:</h4>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Tap Apps and Notifications.
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Tap Advanced.
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Tap Special app access.
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Tap Install unknown apps.
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Tap your web browser app.
                          </li>
                        </v-list-item>
                        <v-list-item style="text-align: left">
                          <li style="margin-top: -40px; margin-left: 20px">
                            Tap the switch next to Allow from this source.
                          </li>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="instructionsdialog = false" class="primary">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <v-card-text>
            <v-container>
              <template>
                <v-row dense v-if="myapps.length > 0 && !isLoading">
                  <v-col
                    v-for="(item, i) in myapps"
                    :key="i"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="3"
                  >
                    <v-card
                      v-if="item.published"
                      class="mx-auto"
                      max-width="250"
                      tile
                      elevation="1"
                      style="padding: 15px; margin: 15px"
                      color="#f5f9fa"
                    >
                      <v-row
                        align="center"
                        justify="center"
                        class="fill-height"
                      >
                        <v-avatar
                          class="rounded-lg profile"
                          color="grey"
                          size="150"
                          tile
                        >
                          <v-img :src="item.iconSrc"></v-img>
                        </v-avatar>
                      </v-row>
                      <v-row align="center" justify="center">
                        <span class="pa-2">
                          <v-list-item-title
                            class="text-h3"
                            v-text="item.name"
                          />
                        </span>
                      </v-row>
                      <v-row align="center" justify="center">
                        <span class="" align="center" justify="center">
                          <v-chip
                            outlined
                            v-text="item.versionSubtitle"
                          ></v-chip>
                          <v-list-item-subtitle
                            style="margin-top: 5px"
                            class="grey--text"
                            v-text="item.lastUpdateSubtitle"
                          />
                        </span>
                      </v-row>
                      <v-row align="center" justify="center" class="pt-2">
                        <v-btn
                          v-if="os != 'other'"
                          small
                          depressed
                          large
                          color="blue"
                          @click.native.prevent="downloadFile(item)"
                          >Download</v-btn
                        >
                        <v-alert
                          v-else
                          dense
                          text
                          type="info"
                          style="margin: 10px 15px 0px 15px"
                        >
                          Downloads are only available from mobile devices
                        </v-alert>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else-if="myapps.length === 0 && !isLoading">
                  <div class="h1">
                    No apps assigned. Please request access through your BV
                    contact.
                  </div>
                </v-row>
                <v-container v-else style="height: 200px">
                  <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                  >
                    <v-col class="subtitle-1 text-center" cols="12">
                      Getting your apps
                    </v-col>
                    <v-col cols="6">
                      <v-progress-linear
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-container>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import * as auth from "@/views/auth/auth";
import { getOS } from "@/helpers";

export default {
  name: "myapps",
  data() {
    return {
      myapps: [],
      os: getOS(),
      title: "",
      isLoading: false,
      instructionsdialog: false,
      downloadSnackbar: false,
      userEmail: ""
    };
  },
  mounted: async function () {
    this.isLoading = true;
    this.title = document.title;
    if (!this.loggedIn()) {
      this.$router.push("login");
    }
    console.log("Found OS: " + this.os);
    const os = this.os;
    document.title = this.title += ` | ${os}`;

    await this.$admin.http.get("/me").then((res) => {
      auth.setUser(res.data.user);
      auth.setMyapps(res.data.applications);
      this.userEmail = res.data.user.email;
      if (os == "other") {
        this.myapps = _.filter(res.data.applications, {
          published: true,
        });
      } else {
        switch (os) {
          case "ios":
            this.myapps = _.filter(res.data.applications, {
              published: true,
              os_ios: true,
            });
            break;
          case "android":
            this.myapps = _.filter(res.data.applications, {
              published: true,
              os_android: true,
            });
            break;
          default:
            this.myapps = [];
            break;
        }
      }
      this.isLoading = false;
      _.forEach(this.myapps, function (ma) {
        ma.color = "#f5f9fa";
        ma.iconSrc =
          process.env.VUE_APP_ICONSTORAGE_URL +
          ma.appid +
          "/" +
          ma.iconSrc +
          process.env.VUE_APP_APPSTORAGE_SASKEY;
        if (os == "ios") {
          ma.packageLink =
            "itms-services://?action=download-manifest&url=" +
            process.env.VUE_APP_APPSTORAGE_URL +
            ma.appid +
            "/app_manifest.plist" +
            process.env.VUE_APP_APPSTORAGE_SASKEY;
        } else if (os == "android") {
          ma.packageLink =
            process.env.VUE_APP_APPSTORAGE_URL +
            ma.appid +
            "/" +
            ma.packageName_android +
            process.env.VUE_APP_APPSTORAGE_SASKEY;
        } else {
          ma.packageLink = "";
        }
        let lastVersionUpdate = new Date(ma.lastVersionUpdate);
        lastVersionUpdate =
          lastVersionUpdate.getMonth() +
          1 +
          "/" +
          lastVersionUpdate.getDate() +
          "/" +
          lastVersionUpdate.getFullYear();
        ma.versionSubtitle =
          os != "other" ? `v${ma["version_" + os]}` : "mobile only";
        ma.lastUpdateSubtitle = "updated " + lastVersionUpdate;
      });
    });
  },
  methods: {
    async downloadFile(app) {
      const os = this.os;
      const appId = app.appid;
      const appVersion = app.versionSubtitle.substring(1);
      const appName = app.name;
      const downloadlog = {
        appId: appId,
        appVersion: appVersion,
        appName: appName,
        os: os,
        userId: this.userEmail
      };
      if (os == "ios") {
        await this.$admin
          .http({
            url: `/applications/downloadI/${appId}`,
            method: "POST",
          })
          .then(async (response) => {
            this.$admin.http.post("/userdownloads", downloadlog);
            const url = response.data;
            const link = document.createElement("a");
            link.href =
              "itms-services://?action=download-manifest&url=" +
              encodeURIComponent(url);
            link.click();
            this.downloadSnackbar = true;
          });
      } else if (os == "android") {
        await this.$admin
          .http({
            url: `/applications/downloadA/${appId}/${app.packageName_android}`,
            method: "POST",
          })
          .then(async (response) => {
            this.$admin.http.post("/userdownloads", downloadlog);
            const url = response.data;
            const link = document.createElement("a");
            link.href = url;
            link.click();
            this.downloadSnackbar = true;
          });
      }
    },
    loggedIn() {
      return !!auth.account;
    },
  },
};
</script>
