<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <applications-show v-if="show" :item="item"></applications-show>
      <applications-form
        v-if="['create', 'edit'].includes(action)"
        :id="id"
        :item="item"
        @saved="onSaved"
      ></applications-form>
    </va-aside-layout>
    <base-material-card
      :icon="resource.icon"
      :title="title"
      color="yellow darken-1"
    >
      <va-list
        ref="list"
        disable-create-redirect
        disable-global-search
        @action="onAction"
        hideDefaultFooter
      >
        <va-data-table
          :fields="fields"
          disable-show-redirect
          disable-edit-redirect
          disable-delete
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "name", sortable: true },
        { source: "version_ios" },
        { source: "version_android" },
        { source: "appid", sortable: true, label: "App ID" },
        { source: "published", sortable: true, type: "boolean" },
        { source: "os_ios", sortable: true, label: "ios", type: "boolean" },
        {
          source: "os_android",
          sortable: true,
          label: "android",
          type: "boolean",
        },
        {
          source: "lastVersionUpdate",
          sortable: true,
          type: "date",
          label: "Last Version Update",
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      action: null,
    };
  },
  methods: {
    showOS() {
      alert(navigator.userAgent);
      document.body.appendChild(`<br/> OS: ${navigator.userAgent}`);
    },
    async onAction({ action, id, item }) {
      if (action === this.action) {
        this.action = null;
      }
      this.action = action;
      if (action == "create") {
        this.asideTitle = `Create New Application`;
      } else {
        this.asideTitle = `Edit ${item.name}`;
      }
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.action = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
