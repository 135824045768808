<template>
  <va-layout>
    <v-app-bar slot="app-bar" color="#48586f" dark style="max-height: 64px">
      <v-btn
        text
        @click="$router.push('myapps')"
        style="max-width: 75%; padding: 0 !important"
        ><img src="../assets/BV_Logo.png" style="max-width: 100%"
      /></v-btn>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="logout()">
            <v-icon v-on="on">mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Log-out</span>
      </v-tooltip>
      <div v-if="admin || manager">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <v-list nav dense>
            <v-list-item @click="$router.push('users')">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Manage Users</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="admin" @click="$router.push('applications')">
              <v-list-item-icon>
                <v-icon>mdi-responsive</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Manage Applications</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <va-aside slot="aside"></va-aside>
    <va-footer slot="footer" :menu="footerMenu">
      &copy; 2021 Black & Veatch
    </va-footer>
  </va-layout>
</template>

<script>
import * as auth from "@/views/auth/auth";

export default {
  name: "App",
  data() {
    return {
      drawer: null,
      mini: false,
      footerMenu: [],
      admin: false,
      manager: false,
    };
  },
  mounted() {
    const self = this;
    const T = setInterval(function () {
      self.admin = self.checkAdmin();
      self.manager = self.checkManager();
      if (auth.catalogUser) {
        clearInterval(T);
      }
    }, 1000);
  },
  methods: {
    logout() {
      auth.signOut();
    },
    checkAdmin() {
      if (auth.catalogUser && auth.catalogUser?.isAdmin != null) {
        return auth.catalogUser.isAdmin;
      } else {
        return false;
      }
    },
    checkManager() {
      if (auth.catalogUser && auth.catalogUser?.isManager != null && !auth.catalogUser.isAdmin) {
        return auth.catalogUser.isManager;
      } else {
        return false;
      }
    },
  },
};
</script>