<template>
  <v-form ref="form">
    <div class="text-center">
      <v-btn
        :loading="!loggedIn()"
        color="primary"
        large
        type="submit"
        text
        rounded
        >{{ loggedIn ? "Loading Apps" : "Logging in..." }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import * as auth from "@/views/auth/auth";
export default {
  async mounted() {
    const T = setInterval(async () => {
      if (this.loggedIn()) {
        clearInterval(T);
        this.$router.push("myapps");
      } else {
        if (!auth.working) {
          await auth.signIn();
        }
      }
    }, 1000);
  },
  methods: {
    loggedIn() {
      return !!auth.account;
    }
  }
};
</script>

<style scoped>
#login-page {
  background-color: var(--v-primary-lighten5);
}
</style>
