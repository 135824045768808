<template>
  <va-show :item="item">
    <va-field source="name.first" label="First Name"></va-field>
    <va-field source="name.last" label="Last Name"></va-field>
    <va-field source="email"></va-field>
    <va-field source="isActive" label="Active"></va-field>
  </va-show>
</template>

<script>
export default {
  props: ["item"]
};
</script>
