import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";

import "vuetify-admin/src/loader";

import { jsonServerDataProvider } from "vuetify-admin/src/providers";
import { en } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";
import * as auth from "../views/auth/auth";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL;

const http = axios.create({
  baseURL,
  headers: { "X-Requested-With": "XMLHttpRequest" }
});

http.interceptors.request.use(
  async request => {
    if (auth.client && auth.account) {
      const { idToken } = await auth.getToken();

      request.headers.Authorization = `Bearer ${idToken}`;
    }

    return request;
  },
  error => {
    Promise.reject(error);
  }
);

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "BV App Catalog",
  routes,
  locales: {
    en
  },
  translations: ["en"],
  dataProvider: jsonServerDataProvider(http),
  resources,
  http,
  options: {
    dateFormat: "long"
  }
});
