<template>
  <va-show :item="item">
    <va-field source="name"></va-field>
    <va-field source="description" multiline></va-field>
    <va-field source="published"></va-field>
  </va-show>
</template>

<script>
export default {
  props: ["item"]
};
</script>
