<template>
  <router-view />
</template>

<script>
export default {
  async mounted() {},
  name: "App"
};
</script>
