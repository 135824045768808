import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Myapps from "@/views/Myapps";
import Login from "@/views/auth/Login";
import Error from "@/views/Error";
import i18n from "@/i18n";

/**
 * Error component
 */
Vue.component("Error", Error);

export default {
  path: "",
  component: AdminLayout,
  meta: {
    title: i18n.t("routes.myapps")
  },
  children: [
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: i18n.t("routes.login")
      }
    },
    {
      path: "/myapps",
      name: "dashboard",
      component: Myapps,
      meta: {
        title: i18n.t("routes.myapps")
      }
    },
    {
      path: "*",
      component: Error,
      meta: {
        title: i18n.t("routes.not_found")
      }
    }
  ]
};
