<template>
  <va-form disable-redirect @saved="$emit('saved')">
    <va-text-input v-model="firstName" label="First Name"></va-text-input>
    <va-text-input v-model="lastName" label="Last Name"></va-text-input>
    <va-text-input v-model="email" type="email" label="Email"></va-text-input>
    <va-boolean-input v-model="isActive" label="Active"></va-boolean-input>
    <va-boolean-input v-if="checkAdmin()" v-model="isAdmin" label="Admin"></va-boolean-input>
    <va-boolean-input v-if="checkAdmin()" v-model="isManager" label="Manager"></va-boolean-input>
    <va-select-input
      v-if="checkAdmin()"
      v-model="applications"
      source="applications"
      reference="applications"
      itemText="name"
      itemValue="id"
      returnObject="true"
      multiple
      :filter="{ published: true }"
    ></va-select-input>
    <v-select
      v-else-if="checkManager()"
      v-model="applications"
      :items="this.myApps"
      itemText="name"
      itemValue="id"
      chips
      label="Applications"
      multiple
    ></v-select>
    <va-action-button
      icon="mdi-floppy"
      color="primary"
      label="save"
      @click="saveUser"
    ></va-action-button>
  </va-form>
</template>

<script>
import * as auth from "@/views/auth/auth";

export default {
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    isActive: "",
    isAdmin: "",
    isManager: "",
    applications: [],
    myApps: []
  }),
  created: async function () {
    if (auth.catalogUserApps) {
      this.myApps = auth.catalogUserApps;
    }
    console.log(this.myApps);
  },
  methods: {
    saveUser: function() {
      let dataObject = {
        name: {
          first: this.firstName,
          last: this.lastName
        },
        email: this.email,
        isActive: this.isActive || false,
        isAdmin: this.isAdmin || false,
        isManager: this.isManager || false,
        applications: []
      };
      if (this.applications) {
        this.applications.forEach(ia =>
          dataObject.applications.push({ id: ia })
        );
      }
      this.$admin.http.post("/users/", dataObject).then(res => {
        this.$emit("saved");
        console.log(res);
      });
    },
    checkAdmin() {
      if (auth.catalogUser && auth.catalogUser?.isAdmin != null) {
        return auth.catalogUser.isAdmin;
      } else {
        return false;
      }
    },
    checkManager() {
      if (auth.catalogUser && auth.catalogUser?.isManager != null && !auth.catalogUser.isAdmin) {
        return auth.catalogUser.isManager;
      } else {
        return false;
      }
    }
  }
};
</script>
