import Vue from "vue";
import VueRouter from "vue-router";
import AuthLayout from "@/layouts/Auth";
//import Login from "@/views/auth/Login";
import Callback from "@/views/auth/Callback";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ResetPassword from "@/views/auth/ResetPassword";
import i18n from "@/i18n";
import * as auth from "./../views/auth/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/forgot-password",
        name: "forgot_password",
        component: ForgotPassword,
        meta: {
          title: i18n.t("routes.forgot_password")
        }
      },
      {
        path: "/reset-password/:token",
        name: "reset_password",
        component: ResetPassword,
        props: true,
        meta: {
          title: i18n.t("routes.reset_password")
        }
      },
      {
        path: "/callback",
        name: "callback",
        component: Callback,
        meta: {
          title: "Callback"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// nav guards
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isLoggedIn = !!auth.account;
    if (isLoggedIn) {
      next();
    } else {
      next({
        name: "login",
        query: { redirect: to.fullPath }
      });
    }
  } else {
    next();
  }
});

export default router;
