<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <users-show v-if="action === 'show'" :item="item"></users-show>
      <users-create v-if="action === 'create'" @saved="onSaved"></users-create>
      <users-edit
        v-if="action === 'edit'"
        :id="id"
        :item="item"
        @saved="onSaved"
      ></users-edit>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title" color="#00988d">
      <va-list
        ref="list"
        disable-create-redirect
        disable-query-string
        disable-global-search
        hide-default-footer
        disable-pagination
        @action="onAction"
        :items-per-page="itemsPerPage"
        :items-per-page-options="[itemsPerPage]"
      >
        <template v-slot:footer>
          <v-pagination
            v-model="currentPage"
            :length="pageCount"
            @input="onPage"
          ></v-pagination>
        </template>

        <template v-slot:default>
          <div class="d-flex justify-content-between">
            <v-text-field
              class="mr-2"
              v-model="search"
              solo
              label="Search"
              clearable
            ></v-text-field>
            <v-select
              v-model="appFilter"
              solo
              label="Application"
              clearable
              :items="applications"
            />
          </div>
          <div>
            <va-data-table
              v-if="itemCount != 0"
              ref="table"
              :fields="fields"
              disable-show-redirect
              disable-edit-redirect
              disable-sort
              disable-delete
              @item-action="onAction"
            />
            <v-progress-linear v-else indeterminate rounded height="6" />
          </div>
        </template>
      </va-list>
    </base-material-card>
    <v-snackbar v-model="snackbar" color="green">{{ snackbarData }}</v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 15,
      itemCount: 0,
      items: [],
      applications: [],
      appFilter: "",
      search: "",
      snackbar: false,
      snackbarData: "",
      fields: [
        { source: "name.first", sortable: true, label: "First Name" },
        { source: "name.last", sortable: true, label: "Last Name" },
        { source: "email", sortable: true, type: "email" },
        {
          source: "isActive",
          sortable: true,
          label: "Active",
          type: "boolean",
        },
        {
          source: "isAdmin",
          label: "Admin",
          type: "boolean",
        },
        {
          source: "isManager",
          label: "Manager",
          type: "boolean",
        },
        {
          source: "applications",
          type: "array",
          attributes: {
            color: "yellow",
            small: true,
            column: true,
            chip: true,
          },
        },
        {
          source: "createdAt",
          sortable: true,
          type: "date",
          label: "Created",
        },
      ],
      filters: [
        {
          source: "email",
          alwaysOn: true,
        },
        {
          source: "name.first",
          alwaysOn: true,
          label: "First Name",
        },
        {
          source: "name.last",
          alwaysOn: true,
          label: "Last Name",
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      action: null,
    };
  },
  async created() {
    this.getApplications();
  },
  methods: {
    async onAction({ action, id, item }) {
      if (action === this.action) {
        this.action = null;
      }
      if (action == "create") {
        this.asideTitle = `Create New User`;
      } else {
        this.asideTitle = `Edit ${item.name.first} ${item.name.last}`;
      }
      this.id = id;
      this.action = action;
      this.item = item;
    },
    async getApplications() {
      await this.$admin.http.get("/applications").then((res) => {
        console.log("apps: ", res);
        res.data.forEach((app) => {
          this.applications.push(app.name);
        });
      });
    },
    onSaved() {
      this.action = null;
      this.showSnackbar();
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    showSnackbar() {
      // TODO: fix this bug
      //this.snackbarData = `${this.item.name.first} ${this.item.name.last} saved.`;
      this.snackbarData = `Saved. 🎈🎈`;
      this.snackbar = true;
    },
    getItemCount() {
      this.loadingData = true;
      if (this.search || this.appFilter) {
        return this.filteredItems.length;
      }
      this.itemCount = this.getItems().length;
      return this.itemCount;
    },
    getItems() {
      this.items = this.$refs.list?.listState?.items;
      return this.items;
    },
    onPage(e) {
      console.log(e);
      this.setHackFilter();
    },
    setHackFilter() {
      // very hack. much bad.
      this.$refs.table.listState = Object.assign(
        {},
        this.$refs.table.listState
      );
      this.$refs.table.listState.items = this.pageItems;
    },
  },
  computed: {
    pageCount() {
      return this.itemsPerPage <= 0
        ? 1
        : Math.ceil(this.getItemCount() / this.itemsPerPage);
    },
    pageStart() {
      if (this.itemsPerPage === -1 || !this.filteredItems.length) return 0;

      return (this.currentPage - 1) * this.itemsPerPage;
    },
    pageStop() {
      if (this.itemsPerPage === -1) return this.filteredItems.length;
      if (!this.filteredItems.length) return 0;

      return Math.min(
        this.filteredItems.length,
        this.currentPage * this.itemsPerPage
      );
    },
    pageItems() {
      try {
        return this.filteredItems.slice(this.pageStart, this.pageStop);
      } catch {
        return [];
      }
    },
    filteredItems() {
      this.currentPage = 1;
      let filteredBySearch = [];
      let filteredByApp = [];

      if (this.search) {
        const lowerSearch = this.search.toLowerCase();

        filteredBySearch = this.items.filter(
          (x) =>
            (x.email && x.email.toLowerCase().includes(lowerSearch)) ||
            (x.name.first &&
              x.name.first.toLowerCase().includes(lowerSearch)) ||
            (x.name.last && x.name.last.toLowerCase().includes(lowerSearch))
        );
      }
      if (this.appFilter) {
        this.items.filter((x) => {
          x.applications.forEach((app) => {
            if (app.name == this.appFilter) {
              filteredByApp.push(x);
            }
          });
        });
      }
      if (this.search && this.appFilter) {
        return filteredBySearch.filter((x) => filteredByApp.includes(x));
      } else if (this.search) {
        return filteredBySearch;
      } else if (this.appFilter) {
        return filteredByApp;
      }
      return this.items;
    },
  },
  watch: {
    filteredItems() {
      this.setHackFilter();
    },
  },
};
</script>
