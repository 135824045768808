<template>
  <div>Loading...</div>
</template>

<script lang="ts">
// import { Vue, Component } from 'vue-property-decorator';
// import useApi, { IApi } from '@/api';

export default {
  data() {},
  methods: {},
  async created() {
    debugger;
    try {
      console.log("In Callback");
    } catch (e) {
      //   this.$router.push({ name: "login", query: { error: "true" } });
      console.log("oof");
    }
  }
};
</script>

<style></style>
