<template>
  <va-form v-if="checkAdmin()" :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-text-input
      source="name.first"
      v-model="item.name.first"
      label="First Name"
    ></va-text-input>
    <va-text-input
      source="name.last"
      v-model="item.name.last"
      label="Last Name"
    ></va-text-input>
    <va-text-input
      source="email"
      v-model="item.email"
      type="email"
    ></va-text-input>
    <va-boolean-input
      source="isActive"
      v-model="item.isActive"
      label="Active"
    ></va-boolean-input>
    <va-boolean-input
      source="isAdmin"
      v-model="item.isAdmin"
      label="Admin"
    ></va-boolean-input>
    <va-boolean-input
      source="isManager"
      v-model="item.isManager"
      label="Manager"
    ></va-boolean-input>
    <va-select-input
      v-model="item.applications"
      source="applications"
      reference="applications"
      itemText="name"
      itemValue="id"
      returnObject="true"
      multiple
    ></va-select-input>
    <va-action-button
      icon="mdi-floppy"
      color="primary"
      label="save"
      @click="saveUser"
    ></va-action-button>
  </va-form>
  <va-show v-else-if="checkManager()" :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-field source="name.first" label="First Name"></va-field>
    <va-field source="name.last" label="Last Name"></va-field>
    <va-field source="email"></va-field>
    <va-field source="isActive" label="Active"></va-field>
    <va-field source="isAdmin" label="Admin"></va-field>
    <va-field source="isManager" label="Manager"></va-field>
    <v-select
      v-model="item.applications"
      :items="this.myApps"
      itemText="name"
      itemValue="id"
      chips
      label="Applications"
      multiple
    ></v-select>
    <va-action-button
      icon="mdi-floppy"
      color="primary"
      label="save"
      @click="saveUser"
    ></va-action-button>
  </va-show>
</template>

<script>
import * as auth from "@/views/auth/auth";

export default {
  props: ["id", "item"],
  data: () => ({
    myApps: []
  }),
  created: async function () {
    if (auth.catalogUserApps) {
      this.myApps = auth.catalogUserApps;
    }
    console.log(this.myApps);
  },
  methods: {
    saveUser: function () {
      let dataObject = {
        name: this.item.name,
        email: this.item.email,
        isActive: this.item.isActive,
        isAdmin: this.item.isAdmin,
        isManager: this.item.isManager,
        applications: [],
      };
      this.item.applications.forEach((ia) =>
        dataObject.applications.push({ id: ia })
      );
      this.$admin.http.put("/users/" + this.id, dataObject).then((res) => {
        this.$emit("saved");
        console.log(res);
      });
    },
    checkAdmin() {
      if (auth.catalogUser && auth.catalogUser?.isAdmin != null) {
        return auth.catalogUser.isAdmin;
      } else {
        return false;
      }
    },
    checkManager() {
      if (auth.catalogUser && auth.catalogUser?.isManager != null && !auth.catalogUser.isAdmin) {
        return auth.catalogUser.isManager;
      } else {
        return false;
      }
    }
  },
};
</script>
