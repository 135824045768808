export default [
  {
    name: "users",
    icon: "mdi-account",
    routes: ["list", "show"],
    actions: ["list", "show", "create", "edit", "delete"]
  },
  {
    name: "applications",
    icon: "mdi-responsive",
    routes: ["list"]
  }
];
